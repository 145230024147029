if ('serviceWorker' in navigator) {
  // We're in a browser context
  // The WonderPush SDK will be loaded by the script tag in index.html
} else if (typeof importScripts === 'function') {
  // We're in a service worker context
  importScripts('https://cdn.by.wonderpush.com/sdk/1.1/wonderpush-loader.min.js');
  WonderPush.init({
    webKey: new URL(location.href).searchParams.get('webKey')
  });
}
